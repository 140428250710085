// Schedule Callback Page constants
export const SCHEDULE_CALLBACK_FORM = [
  {
    component: 'AtomsInput',
    componentClass: 'fullName w-full',
    props: {
      name: 'full_name',
      placeholder: 'Full name',
      label: 'Full name',
      darkPlaceholder: true
    }
  },
  {
    component: 'AtomsInput',
    componentClass: 'sm:w-1/2 w-full',
    props: {
      name: 'phone',
      placeholder: 'Phone number',
      modelType: 'number',
      label: 'Phone number',
      maxlength: '10',
      darkPlaceholder: true
    }
  },
  {
    component: 'MoleculesSelectDropdown',
    componentClass: 'sm:w-1/2 w-full',
    props: {
      name: 'state',
      label: 'State',
      placeholder: 'State',
      dataInputDisplay: 'name',
      dataListDisplay: 'name',
      dataValue: 'value',
      dropdownClass: ' z-[99]'
    }
  },
  {
    component: 'AtomsInput',
    componentClass: 'w-full',
    props: {
      name: 'user_email',
      placeholder: 'Email Address',
      label: 'Email',
      darkPlaceholder: true
    }
  },
  {
    component: 'MoleculesSelectDropdown',
    componentClass: 'sm:w-1/2 w-full',
    props: {
      name: 'date',
      label: 'Date',
      placeholder: 'Date',
      dataListDisplay: 'name',
      dataInputDisplay: 'name',
      dataValue: 'value',
      dropdownClass: 'z-[99]'
    }
  },
  {
    component: 'MoleculesSelectDropdown',
    componentClass: 'sm:w-1/2 w-full',
    props: {
      name: 'time',
      label: 'Time',
      placeholder: 'Time',
      dataListDisplay: 'label',
      dataInputDisplay: 'label',
      dataValue: 'value',
      dropdownClass: 'z-[99]'
    }
  }
]

// Form used in components/molecules/ScheduleCallback/Generic/index.vue
export const REQUEST_CALLBACK_FORM = [
  {
    component: getComponent('AtomsInput'),
    componentClass: 'fullName w-full',
    props: {
      name: 'full_name',
      placeholder: 'Full name',
      label: 'Full name',
      darkPlaceholder: true
    }
  },
  {
    component: getComponent('AtomsInput'),
    componentClass: 'w-full',
    props: {
      name: 'phone',
      placeholder: 'Phone number',
      modelType: 'number',
      label: 'Phone number',
      maxlength: '10',
      darkPlaceholder: true
    }
  }
]

// Form used in components/molecules/ScheduleCallback/Generic/index.vue
export const REQUEST_CALLBACK_FORM_NEW = [
  {
    component: getComponent('AtomsInput'),
    componentClass: 'fullName w-full',
    props: {
      name: 'full_name',
      placeholder: 'Full name',
      label: 'Full name',
      darkPlaceholder: true
    }
  },
  {
    component: getComponent('AtomsInput'),
    componentClass: 'w-full',
    props: {
      name: 'phone',
      placeholder: 'Phone number',
      modelType: 'number',
      label: 'Phone number',
      maxlength: '10',
      darkPlaceholder: true
    }
  },
  {
    component: getComponent('MoleculesSelectDropdown'),
    componentClass: 'w-full',
    props: {
      name: 'state',
      label: 'State',
      placeholder: 'State',
      dataInputDisplay: 'name',
      dataListDisplay: 'name',
      dataValue: 'value',
      dropdownClass: ' z-[99]'
    }
  },
  {
    component: getComponent('AtomsInput'),
    componentClass: 'w-full',
    props: {
      name: 'user_email',
      placeholder: 'Email Address',
      label: 'Email',
      darkPlaceholder: true
    }
  }
]

export const SCHEDULE_CALLBACK_ERROR_MAP = {
  full_name: 'Please enter full name',
  phone: 'Please enter valid number',
  state: 'Please select state',
  user_email: 'Please enter a valid email address',
  time: 'Please select time',
  date: 'Please select date'
}

export const REQUEST_CALLBACK_ERROR_MAP = {
  full_name: 'Please enter full name',
  phone: 'Please enter valid 10 digit phone number',
  state: 'Please select state',
  user_email: 'Please enter a valid email address'
}

export const COLLECTION_NOTICE = {
  title: 'Collection Notice',
  content: '<p>Personal information is being collected by Compare Club (CC).</p>' +
        '<p>Personal information is being collected by CC so that CC can provide and market its products and services ' +
        'to you which may include telephone calls to you about our products and services. If CC does not collect ' +
        'personal information from you, CC will not, nor will our partners, be able to provide its products or ' +
        'services to you. </p>' +
        '<p>To provide CC\'s products and services, CC may need to disclose personal information to commercial partners ' +
        'to fulfil your requests or third party service providers who provide services to CC. For more information, ' +
        'please review our Privacy Policy.</p>'
}
